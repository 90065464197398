@import 'variables.scss';

a,
p,
h1,
h2,
h3,
h4,
h5,
h6,
button {
    overflow: hidden;
    text-overflow: ellipsis;
}

.s12 {
    font-size: 12px !important;
}

.s16 {
    font-size: 16px !important;
}

.s20 {
    font-size: 20px !important;
}

.s22 {
    font-size: 22px !important;
}

.s24 {
    font-size: 24px !important;
}


body {
    margin: 0;
    font-family: 'roboto';
    text-shadow: 0px 0px 1px $primary-color-light;
}

.headerContainer {
    margin-bottom: 20px;

    .headerCard {
        margin-bottom: 20px;

        @media (max-width: 1023px) {
            margin-bottom: 0;
        }
    }
}

.primary {
    color: $primary-color !important;
}

.accent {
    color: $accent-color !important;
}

.mat-card-global {
    background-color: rgba(255, 255, 255, 1); // text-shadow: 0px 0px 1px #F21F2371f;
    border-radius: $default-border-radius !important;
}

mat-expansion-panel {
    border-radius: 0 !important;
}

.mat-flat-button,
button,
.mat-button {
    margin-left: 0 !important;
    border-radius: $default-border-radius !important;
}

mat-card-title {
    margin-left: -8px
}

mat-card-subtitle {
    margin-left: -8px
}

.mat-card-header-text {
    margin-left: 10px !important;
}

.normalize-button {
    margin-left: 0 !important;
}

.justFontHeader {
    color: white;
    margin: 0 0 20px 0;
}

mat-spinner {
    margin: 0 auto;
}

.snack-bar-color-success,
.success-color {
    color: $success-color;
}

.snack-bar-color-error,
.error-color {
    color: $error-color-light;
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    font-display: swap;
}

.error {
    color: $error-color !important;

    &.bg.hue {
        background-color: $error-color !important;

        color: white !important;

        p,
        h2,
        h3 {
            color: white !important;
        }
    }
}

.mat-dialog-content {
    max-height: 80vh !important;
}

.noMargin {
    margin: 0 !important;
}

.disabledButton {
    background-color: $light-grey !important;
    pointer-events: none;
    cursor: default;
    text-decoration: none;
    opacity: .5;
}

.dialogTitleWrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    left: 0;
    left: -24px;
    padding-left: 24px;
    padding-right: 24px;
    box-shadow: 0px 16px 12px 0px rgb(0 0 0 / 5%);
    padding-top: -20px;
    z-index: 1000;

    button {
        position: relative;
        top: -10px;
    }
}

.dialogFooter {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

}

.space {
    flex: 1;
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

.staging {
    background-color: red !important;
}

.stagingHeader {
    height: 32px;
    background-color: rgb(194, 0, 0);
    text-align: center;

    p {
        color: white;
        margin: 0;
        position: relative;
        top: 6px;
    }
}