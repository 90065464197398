$primary-color-light: #BFCBD5;
$primary-color-ultra-light: rgb(237, 246, 250);
$primary-color: #C1002B;
$secondary-color: #BCBDC0;
$accent-color: #003C76;
$amber: #ffc107;
$green: #0F8553;
$success-color: white;
$error-color: #C1002B;
$error-color-light: #ff93ab;
$default-border-radius: 3px;
$light-grey: #646363;
$light-background: #e7e4e4;
$default-max-width: 650px;
$default-border-radius: 3px;